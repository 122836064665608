<template>
  <div style="height: inherit">

    <!-- ECommerce Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <feather-icon
                icon="MenuIcon"
                class="d-block d-lg-none"
                size="21"
              />
              <div class="search-results">
                {{ pagination.totalRows }} results found
              </div>
            </div>
            <div class="view-options d-flex ">

              <b-button
                to="/add-product"
                class="btn-icon grid-btn"
                variant="outline-primary" >
                <feather-icon
                  icon="EditIcon"
                  svg-classes="hover:text-primary"
                  class=""
                />
                Add Product
              </b-button>

              <!-- Sort Button -->
              <b-dropdown
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :text="sortBy.text"
                right
                variant="outline-primary"
                class="ml-1"
              > 
                <b-dropdown-item
                  v-for="sortOption in sortByOptions"
                  :key="sortOption.value"
                  @click="sortBy=sortOption"
                >
                  {{ sortOption.text }}
                </b-dropdown-item>
              </b-dropdown>

              <!-- <b-button
                :to="{name: 'marketplace-checkout'}"
                class="ml-1"
                variant="primary"
              >
                <feather-icon
                  icon="ShoppingCartIcon"
                  svg-classes="hover:text-primary"
                  class=""
                />
              </b-button> -->
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Searchbar -->
    <div class="ecommerce-searchbar mt-1">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="squery"
              placeholder="Search Product"
              class="search-product"
              @keyup="searchText"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>

    <!-- Prodcuts -->
    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
      <section :class="itemView" style="min-height: 60vh;">
        <template
          v-for="product in products"
        >
            <!-- v-if="product.stock > 0 || ($route.name === 'marketplace-manage-products')" -->
          <b-card
            :key="product.id"
            class="ecommerce-card"
            no-body
          >
            <b-card-header
              v-if="$route.name === 'marketplace-manage-products'" 
            >
              <p></p>
              <div>
                <b-button
                  :to="{ name: 'marketplace-edit', params: { slug: product._id } }"
                  class="ml-1"
                  variant="primary">
                  <feather-icon
                    icon="EditIcon"
                    size="20"
                    svg-classes="hover:text-primary"
                  />
                </b-button>
                <b-button 
                  class="ml-1"
                  variant="primary">
                  <feather-icon
                    icon="Trash2Icon"
                    size="20"
                    svg-classes="hover:text-primary"
                    @click="removeProduct(product._id, product.product_name)"
                  />
                </b-button>
              </div>
            </b-card-header>

            <div class="item-img text-center d-flex justify-content-center">
              <b-link :to="{ name: 'marketplace-details', params: { slug: product._id } }">
                <b-img
                  :alt="`${product.product_name}`"
                  fluid
                  class="card-img-top"
                  :src="product.image"
                />
              </b-link>
            </div>

            <!-- Product Details -->
            <b-card-body>
              <div class="item-wrapper">
                <div class="item-rating">
                  <ul class="unstyled-list list-inline">
                    <li
                      v-for="star in 5"
                      :key="star"
                      class="ratings-list-item"
                      :class="{'ml-25': star-1}"
                    >
                      <feather-icon
                        icon="StarIcon"
                        size="16"
                        :class="[{'fill-current': star <= product.rating}, star <= product.rating ? 'text-warning' : 'text-muted']"
                      />
                    </li>
                  </ul>
                </div>
                <div>
                  <h6 class="item-price">
                    ${{ product.price }}
                  </h6>
                </div>
              </div>
              <h6 class="item-name">
                <b-link
                  :to="{ name: 'marketplace-details', params: { slug: product._id } }"
                  class="text-body"
                >
                  {{ product.product_name }}
                </b-link>
                <!-- <b-card-text class="m-0">
                  <small>
                    Stocks: {{ product.stock }}
                  </small>
                </b-card-text> -->

                <!-- <b-card-text>Available - 
                  <span v-if="product.stock > 0" class="text-success">In stock</span>
                  <span v-else class="text-danger">Out of Stock</span></b-card-text> -->

                <b-card-text class="item-company">
                  By <b-link class="ml-25">
                    {{ product.manufactured }}
                  </b-link>
                </b-card-text>
              </h6>
              <b-card-text class="item-description">
                {{ product.descr }}
              </b-card-text>
            </b-card-body>

            <!-- Product Actions -->
            <div class="item-options text-center">
              <div class="item-wrapper">
                <div class="item-cost">
                  <h4 class="item-price">
                    ${{ product.price }}
                  </h4>
                </div>
              </div>
              <b-button
                variant="light"
                tag="a"
                :disabled="$store.state.auth.ActiveUser.email_address === product.created_by"
                class="btn-wishlist"
                @click="
                  product.isInWishlist
                    ? removeWishlist(product.isInWishlist)
                    : addWishlist(product._id)
                "
              >
                <feather-icon
                  icon="HeartIcon"
                  class="mr-50"
                  :class="{'text-danger': product.isInWishlist}"
                />
                <span>Wishlist</span>
              </b-button>
              <b-button
                v-if="product.stock > 0 || ($route.name === 'marketplace-manage-products')"
                :variant="product.isInCart ? 'outline-primary' : 'primary'"
                tag="a"
                :disabled="$store.state.auth.ActiveUser.email_address === product.created_by"
                class="btn-cart"
                @click="
                  product.isInCart
                    ? $router.push({name: 'marketplace-checkout'})
                    : addToCart(product._id)
                "
              >
                <feather-icon
                  icon="ShoppingCartIcon"
                  class="mr-50"
                />
                <span>{{ product.isInCart ? 'View In Cart' : 'Add to Cart' }}</span>
              </b-button>
              <b-button
                v-else
                :variant="'outline-danger'"
                :disabled="true"
                class="btn-cart"
              >
                <feather-icon
                  icon="ShoppingCartIcon"
                  class="mr-50"
                />
                <span>Out of Stock</span>
              </b-button>
            </div>
          </b-card>
        </template>
      </section>
    </b-overlay>

    <!-- Pagination -->
    <section>
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="pagination.currentPage"
            :total-rows="pagination.totalRows"
            :per-page="pagination.perPage"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <shop-left-filter-sidebar
        :filters="filters"
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @filter-changed="getProducts"
      />
    </portal>

  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'

import {
  BCard,
  BButton,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  VBModal,
  BBadge,
  BAvatar,
  BOverlay,
  BPagination,
  BFormCheckbox,
  BFormSelect,
  BLink,
  BFormInvalidFeedback,
} from 'bootstrap-vue'

import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import { useShopFiltersSortingAndPagination, useShopUi, useShopRemoteData } from './useMarketplaceData'
import ShopLeftFilterSidebar from './MarketplaceShopLeftFilterSidebar.vue'


export default {
  directives: {
    Ripple,
  },
  components: {
    VueGoodTable,
    BCard,
    BPagination,
    BButton,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BOverlay,
    BModal,
    BFormSelect,
    BFormCheckbox,
    BLink,
    BFormInvalidFeedback,
    VuePerfectScrollbar,
    ShopLeftFilterSidebar,
  },
  data() {
    return {
      totalProducts: 100,
      itemView: 'grid-view',
      products: [],
      sortBy: { text: 'Featured', value: 'featured' },
      sortByOptions: [ { text: 'Featured', value: 'featured' }, { text: 'Lowest', value: 'price-asc' }, { text: 'Highest', value: 'price-desc' } ],
      squery : '',
      billing_info: {},
      show_bulk: true,
      isMounted: false,
      receipts: [],
      receipt_email: null,
      current_employer: '',
      action_view: 'dark',
      action_text: 'Actions',
      action_icon: 'info_outline',
      pagination: {
        currentPage: 1,
        totalRows: 1,
        perPage: 12,
      },
      isLoading: true,

      // sidebar options
      price_options: [],
    }
  },
  watch: {
    '$route.name': function(newval) {
      this.getProducts()
    },
    'pagination.currentPage': function (val, oldVal) {
      this.getProducts()
    },
    'pagination.perPage': function (val, oldVal) {
      this.getProducts()
    },

  },
  created() {
    this.get_all_filters_options()
    this.getProducts()
  },
  setup() {
    const {
      filters, filterOptions, 
    } = useShopFiltersSortingAndPagination()

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      filters,
      filterOptions,
      mqShallShowLeftSidebar,
    }
  },
  methods: {
    getProducts(isLoading = true) {
      this.isLoading = isLoading

      let query = ''
      query += `${this.$attachQuerySymbol(query)}pagination=${JSON.stringify(this.pagination)}`

      if (this.$route.name === 'marketplace-manage-products') {
        query += `${this.$attachQuerySymbol(query)}filtered_created_by=${this.$store.state.auth.ActiveUser.email}`
      } else {
        query += `${this.$attachQuerySymbol(query)}filtered_not_created_by=${this.$store.state.auth.ActiveUser.email}`
      }

      query += `${this.$attachQuerySymbol(query)}filtered_price_range=${JSON.stringify(this.filters.priceRangeDefined)}`

      this.$http
        .get(`/api/product${query}`)
        .then(response => {
          const { success, products } = response.data
          if (success) {
            this.products = products
            this.pagination = response.data.pagination
          } else {
            this.products = []
          }

          this.isLoading = false
        })
        .catch(error => {
          //
        })
        
    },
    searchText() {

      let query = ''
      query += `${this.$attachQuerySymbol(query)}query=${this.squery}`
      this.$http
        .get(`/api/search-product${query}`)
        .then(response => {
          const { success, products } = response.data
          if (success) {
            this.products = products
          }
        })
        .catch(error => {
          //
        })
    },

    addWishlist(product_id) {
      const all_data = {
        email: this.$store.state.auth.ActiveUser.email,
        product_id,
      }

      this.$http
        .post(`/api/add-wishlist/`, { data: all_data })
        .then(response => {
          const { success, message } = response.data

          if (success) {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Product Notification',
                  icon: 'CheckCircleIcon',
                  text: message,
                  variant: 'success',
                },
              },
              { timeout: this.$longestTimeout },
            )
          } else {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Product Notification',
                  icon: 'AlertCircleIcon',
                  text: 'Failed adding to wishlist',
                  variant: 'error',
                },
              },
              { timeout: this.$longestTimeout },
            )
          }

          this.isLoading = false
          this.getProducts(false)
        })
        .catch(error => {
          // alert('ERROR')
          this.isLoading = false
        })
    },
    removeWishlist(wishlist_id) {
      this.$http
        .delete(`/api/wishlist/${wishlist_id}`)
        .then(response => {
          const { success, message } = response.data

          if (success) {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Product Notification',
                  icon: 'CheckCircleIcon',
                  text: message,
                  variant: 'success',
                },
              },
              { timeout: this.$longestTimeout },
            )
          } else {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Product Notification',
                  icon: 'AlertCircleIcon',
                  text: message,
                  variant: 'error',
                },
              },
              { timeout: this.$longestTimeout },
            )
          }

          this.isLoading = false
          this.getProducts(false)
        })
        .catch(error => {
          // alert('ERROR')
          this.isLoading = false
        })
    },

    addToCart(product_id) {
      const form_data = {}
      form_data.quantity = 1

      this.$http
        .post(`/api/add-to-cart/${product_id}`, {
          form_data,
        })
        .then(response => {
          const { success, message } = response.data

          if (success) {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Operation Success',
                  icon: 'CheckCircleIcon',
                  text: message,
                  variant: 'success',
                },
              },
              { timeout: this.$longestTimeout },
            )
          } else {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Operation Failed',
                  icon: 'AlertCircleIcon',
                  text: message,
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )
          }

          this.getProducts(false)
        })
        .catch(() => {
          // this.isLoading = false
        })
    },

    get_all_filters_options() { // get the options for all the filters on the sidebar like price min and max price range, list of categories
      this.$http
        .get(`/api/products-filters-options`)
        .then(response => {
          const { success, output } = response.data
          if (success) {
            // eslint-disable-next-line prefer-arrow-callback
            this.price_options = output.price_options.sort(function(a, b) { 
              if (a === Infinity) return 1; 
              else if( isNaN(a)) return -1;
              else return a - b;
            })

            // this.filters.priceRange = [0, this.price_options[this.price_options.length - 1]]
            // this.filters.priceRange = [0, 6000]
          }
        })
        .catch(() => {})
    },

    removeProduct(id,product_name){
      this.$bvModal
          .msgBoxConfirm(
            `Are you sure want to delete product ${product_name}`,
            {
              title: 'Confirm User Deletion',
              size: 'sm',
              okVariant: 'danger',
              okTitle: 'Delete',
              cancelTitle: 'Cancel',
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            },
          )
          .then(value => {
            if (value) {
              this.deleteRecord(id)
            }
          })  
      },
      deleteRecord(id){
        
        const all_data = { productid: id }
      this.$http
          .post(`/api/remove-product/`, {data : all_data})
          .then(response => {
            const { success } = response.data
            const { message } = response.data
            this.isLoading = false
            if (success) {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'Product Notification',
                    icon: 'AlertCircleIcon',
                    text: message,
                    variant: 'success',
                  },
                },
                { timeout: this.$longestTimeout },
              )
            this.getProducts()
            } else {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'Product Notification',
                    icon: 'AlertCircleIcon',
                    text: `Can't Delete product, try again later`,
                    variant: 'error',
                  },
                },
                { timeout: this.$longestTimeout },
              )
            }
          })
          .catch(error => {
            alert('ERROR')
          })

      }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
